import React from 'react'
import Layout from '../components/layout'

export default function testing() {
  return (
    <Layout>
      <h1>hello from testing</h1>
    </Layout>
  )
}
